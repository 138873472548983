import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { mainnet, arbitrum, solana, solanaDevnet, solanaTestnet, sepolia, bsc, bscTestnet, } from '@reown/appkit/networks'
import type { AppKitNetwork } from '@reown/appkit/networks'
import { SolanaAdapter } from '@reown/appkit-adapter-solana/react'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { cookieStorage, createStorage } from 'wagmi'

export const ethersAdapter = new EthersAdapter()

// Get projectId from https://cloud.reown.com
// Test Saniya's project Id
// export const projectId = "eec37c9d848952141962d806bc58e9a0"; 

// Main Project Id For live
export const projectId = "f85b707a6f79c1ebb216039de8d405b6";


if (!projectId) {
  throw new Error('Project ID is not defined')
}

export const metadata = {
  name: "CrowdWisdom360",
  description: "AppKit Example",
  url: "https://investing.crowdwisdom.live",
  icons: ["https://assets.reown.com/reown-profile-pic.png"],
}


// for custom networks visit -> https://docs.reown.com/appkit/react/core/custom-networks
export const networks = [mainnet, arbitrum, solana, solanaDevnet, solanaTestnet, sepolia, bsc, bscTestnet] as [AppKitNetwork, ...AppKitNetwork[]]


export const solanaNetworks = [solana, solanaDevnet, solanaTestnet] as [AppKitNetwork, ...AppKitNetwork[]];
export const ethereumNetworks = [mainnet, arbitrum, sepolia, bsc, bscTestnet];

//Set up the Wagmi Adapter (Config)
export const wagmiAdapter = new WagmiAdapter({
  storage: createStorage({
    storage: cookieStorage,
  }),
  ssr: true,
  projectId,
  networks,

})

export const store = {
  accountState: {},
  networkState: {},
  appKitState: {},
  themeState: { themeMode: 'light', themeVariables: {} },
  events: [],
  walletInfo: {},
  solanaProvider: null,
  solanaConnection: null
}


// Set up Solana Adapter
export const solanaWeb3JsAdapter = new SolanaAdapter({
  wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()]
})

export const config = wagmiAdapter.wagmiConfig

